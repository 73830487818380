import { gql } from '@apollo/client';
import { captureException } from '@sentry/node';
import getClient from 'Shared/utils/apollo-client';

const GET_NEAREST_PROJECTS = gql`
  query GetNearestProjects($getNearestProjectsInput: GetNearestProjectsInput) {
    getNearestProjects(GetNearestProjectsInput: $getNearestProjectsInput)
  }
`;

export const getNearestProjectsGql = async ({
  apiToken,
  latitude,
  longitude,
  currentProjectId,
  activeOnly,
  limit,
  maxDistance,
  spherical,
  includeInternal,
  customer,
}) => {
  try {
    const client = getClient(apiToken);
    const { data } = await client.query({
      query: GET_NEAREST_PROJECTS,
      variables: {
        getNearestProjectsInput: {
          latitude: String(latitude),
          longitude: String(longitude),
          currentProjectId,
          activeOnly,
          limit,
          maxDistance,
          spherical,
          includeInternal,
          customer,
        },
      },
      context: {
        fetchOptions: {
          method: 'GET',
        },
      },
    });
    return data.getNearestProjects;
  } catch (error) {
    console.error(error);
    captureException(error);
    return null;
  }
};
