import * as React from 'react';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/node';
import Head from 'next/head';
import { decode } from 'jsonwebtoken';
import { signIn, useSession } from 'next-auth/react';
import { SeoMetaInfo } from 'Atoms';
import { HubPagesTemplate as Template } from 'Templates';
import {
  useProject,
  useUser,
  useAnalytics,
  MixpanelEventTypes,
  useMap,
  useProposalContext,
} from 'Client/utils/hooks';
import {
  confirmUserContributions,
  fetchContributionById,
} from 'Client/services/contributions';
import { sendSurveyRequest } from 'Client/services/delighted';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { ContributionGaudi } from 'Shared/types/contribution';
import {
  CONFIRMATION_EMAIL_SENT_ITEM,
  CONTRIBUTION_SESSION_ITEM,
  removeLocalItem,
} from 'Client/utils/localstorage';
import { confirmCommentedOnAllProposals } from 'Client/services/proposals';
import { Sharing } from 'Client/components/organisms';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { getProposalTitleFromToken } from './utils';
import { DecodedToken, ThanksPageProps } from './types';
import { ThanksMessage } from './components/ThanksMessage';
import { CustomThanks } from './components/CustomThanks';
import { ContinueMessage } from './components/ContinueMessage';
import { OtherPlanningApps } from './components/OtherPlanningApps';
import { OtherProposals } from './components/OtherProposals';
import {
  SetWidthContainer,
  DivisionContainer,
  FullWidthContainer,
  DarkFullWidthContainer,
  FlexContainer,
  CompleteStateContainer,
  ThanksPageNextTileContainer,
} from './Thanks.style';
import { Stats } from './components/Stats';
import { Title } from './components/Title';
import { ContributionVisibility } from './components/ContributionVisibility';
import { UserContributions } from './components/UserContributions/UserContributions';
import { CustomerProjects } from './components/CustomerProjects';
import { NearProjects } from './components/NearProjects';

const FROM_GAUDI_DB = true;

const ThanksPage: React.FC<ThanksPageProps> = ({
  proposalTitle: propTitle,
  otherProposals,
  proposals,
  planningApps,
  otherPlanningApps,
  isMap,
  contributions,
  proposalsLeftToComment,
  removeToken = false,
  ...props
}: ThanksPageProps) => {
  const { query, locale, ...router } = useRouter();
  const [{ contributionId }, dispatch] = useProposalContext();
  const {
    state: { contributionFlow: mapContributionFlow },
  } = useMap();
  const { apiToken } = useUtils();
  const { user } = useUser();
  const project = useProject();
  const { trackEvent } = useAnalytics();
  const [, setError] = React.useState('');
  const [contribution, setContribution] = React.useState(undefined);
  const [commentedOnAllProposals, setCommentedOnAllProposals] =
    React.useState(false);
  const [otherProposalsState, setOtherProposalsState] = React.useState(
    proposals || []
  );
  const [otherPlanningAppsState, setOtherPlanningAppsState] =
    React.useState(planningApps);
  const [proposalTitle, setProposalTitle] = React.useState(propTitle || '');

  const isPlanningApp = !isMap && project.features?.planningApps;
  const isGla = project?.features?.glaPlanningApps;
  const confirmLogicOnGql = project?.features?.confirmLogicOnGql;
  const allProposalsCompleted = !proposalsLeftToComment?.length;
  /* We don't want to trigger gaming check when user reaches thanks page at the moment
   * as it takes some good seconds for the check we're removing it for now
   */
  const ignoreGaming = true;

  const { data: session } = useSession();

  React.useEffect(() => {
    // empty the answers
    if (dispatch) {
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_ANSWERS,
        answers: {},
      });
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_VOICE_ANSWERS,
        voiceAnswers: {},
      });
    }
  }, [dispatch]);

  const trackConfirmationMixpanelEvent = (
    status: string,
    authenticationStatus: string,
    contributionIdsConfirmed: Array<string>
  ) => {
    trackEvent(MixpanelEventTypes.CONFIRMS_CONTRIBUTIONS, {
      path: router.asPath,
      status,
      authenticationStatus,
      contributionIdsConfirmed,
    });
  };

  const handleDecodedToken = async (decodedToken: DecodedToken) => {
    try {
      let { updatedUser } = props;
      let confirmFlowSuccessRes = props.confirmFlowSuccess;
      const { confirmedContributions } = props;
      const { contextId, context, comment_id } = confirmLogicOnGql
        ? {
            contextId: props?.tokenData?.contextId,
            context: props?.tokenData?.context,
            comment_id: props?.tokenData?.comment_id,
          }
        : decodedToken;
      const contribution = (await fetchContributionById(
        contextId,
        FROM_GAUDI_DB
      )) as ContributionGaudi;
      setContribution(contribution);
      setOtherProposalsState(
        proposals?.filter((pr) => pr.slug !== contribution.sectionId)
      );
      if (!confirmLogicOnGql) {
        // GET the userId and the projectId from the token's contextId (contributionId) from the gaudi db
        // (because in the anonymous flow on gaudi updates the contribution with the new user id, but
        // it doesn't update the contribution in the acorn db)

        const res = await confirmUserContributions(
          contribution as ContributionGaudi,
          project,
          user,
          locale,
          ignoreGaming,
          apiToken
        );
        updatedUser = res.user;
        confirmFlowSuccessRes = res.success;
        confirmedContributions.push(...res.confirmedContributions);
        if (confirmFlowSuccessRes) {
          if (!user) {
            console.warn(
              'Warning: User not logged in - trying to used the old login method!'
            );
          } else {
            //  already logged in
            trackConfirmationMixpanelEvent(
              'Successful',
              'Already logged in',
              confirmedContributions
            );
          }
        } else {
          // failed to confirm
          trackConfirmationMixpanelEvent(
            'Fail',
            'Failed confirmation flow - user login not handled',
            []
          );
        }
      } else if (removeToken) {
        const href = router.asPath.split('?')[0];
        router.replace(href);
      }

      // We are dispatching the delighted survey email request for user here
      await sendSurveyRequest({ id: user?._id || updatedUser?._id });
      if (isPlanningApp) {
        setOtherPlanningAppsState(
          planningApps.filter(
            (planApp) => planApp.slug !== contribution.sectionId
          )
        );
      }
      const title = await getProposalTitleFromToken({
        contributionType: context,
        commentId: comment_id,
        projectId: project._id,
        contribution,
        isPlanningApp,
        lang: locale,
        isGla,
      });
      setProposalTitle(title);
    } catch (err) {
      console.error('handleDecodedToken() error: ', err);
      captureException(err);
    }
  };

  const handleCommentedOnAllProposals = async () => {
    const hasCommentedOnAllProposals = await confirmCommentedOnAllProposals({
      projectId: project._id,
      userId: user._id,
      proposals: otherProposals,
    });

    setCommentedOnAllProposals(hasCommentedOnAllProposals);
  };

  React.useEffect(() => {
    if (query.token) {
      const decodedToken = decode(query.token as string) as DecodedToken;
      const userNotLoggedIn = !user || !session;
      const useLoginV2 = project?.features?.useLoginV2;

      if (decodedToken && userNotLoggedIn && useLoginV2) {
        console.log('User is not logged in. Logging in...');

        signIn('credentials', {
          userId: decodedToken.userId,
          callbackUrl: `${window.location.origin}/thanks?token=${query.token}`,
        });

        return;
      }

      removeLocalItem(CONTRIBUTION_SESSION_ITEM);
      removeLocalItem(CONFIRMATION_EMAIL_SENT_ITEM);

      if (decodedToken && decodedToken.contextId) {
        handleDecodedToken(decodedToken);
      } else {
        setError('The contribution was not found or the token is invalid.');
        trackConfirmationMixpanelEvent(
          'Fail - invalid token or not found contribution',
          'Failed confirmation flow - user login not handled',
          []
        );
        captureException(
          'Error in useEffect @ ThanksPage.tsx: Invalid token or contribution not found.'
        );
      }
    }
  }, [query]);

  const fetchContribution = async (id: string) => {
    const contribution = await fetchContributionById(id);
    setContribution(contribution);
  };

  React.useEffect(() => {
    const contrId =
      isMap && mapContributionFlow
        ? mapContributionFlow?.contributionId
        : contributionId;
    if (user && contrId) {
      // fetch contribution if already within CF
      fetchContribution(contrId);
    }
  }, [contributionId, user, mapContributionFlow]);

  React.useEffect(() => {
    if (user && !isPlanningApp) {
      handleCommentedOnAllProposals();
    }
  }, [user]);

  const renderCustomThanksPage = !!project.customThanksPage;
  const thanksPageV2 = project?.features?.thanksPageV2;

  const renderThanksPage = () => {
    if (renderCustomThanksPage) return <CustomThanks />;

    if (thanksPageV2)
      return (
        <FlexContainer>
          <DivisionContainer>
            <Stats contribution={contribution}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Title type={contribution?.type} hideTick />
              </div>
            </Stats>
          </DivisionContainer>
          <ThanksPageNextTileContainer>
            <UserContributions
              project={project}
              nextProposals={proposalsLeftToComment}
              totalComments={contributions}
            />
          </ThanksPageNextTileContainer>
        </FlexContainer>
      );

    return (
      <FullWidthContainer>
        <SetWidthContainer>
          <Title type={contribution?.type} />
          <Stats contribution={contribution} />
          <ThanksMessage
            title={proposalTitle}
            contribution={contribution}
            commentedOnAllProposals={commentedOnAllProposals}
            isMap={isMap}
          />
          {!isMap && (
            <ContinueMessage
              title={proposalTitle}
              contribution={contribution}
              isMap={isMap}
            />
          )}
        </SetWidthContainer>
      </FullWidthContainer>
    );
  };

  return (
    <Template {...props}>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <SeoMetaInfo
        projectStage={project.stage}
        projectName={project.name}
        page="proposal"
      />
      {renderThanksPage()}
      <FullWidthContainer thanksPageV2={thanksPageV2}>
        {thanksPageV2 && <ContributionVisibility projectName={project.name} />}
        <Sharing>
          <Sharing.Section thanksPageV2={thanksPageV2} />
        </Sharing>
      </FullWidthContainer>
      <DarkFullWidthContainer>
        {isPlanningApp ? (
          <OtherPlanningApps
            otherPlanningApps={otherPlanningApps}
            otherPlanningAppsState={otherPlanningAppsState}
          />
        ) : thanksPageV2 && allProposalsCompleted ? (
          <CompleteStateContainer>
            <CustomerProjects />
            <NearProjects />
          </CompleteStateContainer>
        ) : (
          <OtherProposals
            id="thanks-page-other-proposals"
            otherProposals={
              thanksPageV2 ? proposalsLeftToComment : otherProposals
            }
            otherProposalsState={otherProposalsState}
          />
        )}
      </DarkFullWidthContainer>
    </Template>
  );
};

export { ThanksPage };
