import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { captureMessage, captureException } from '@sentry/node';
import { useLazyQuery } from '@apollo/client';
import { ProposalPageContent } from 'Client/pages/proposals';
import { CustomProposalCard } from 'Client/pages/hub/components/DynamicProposals/components';
import { Page, ProjectCardData } from 'Shared/types/page';
import {
  scrollTo,
  SCROLL_TO_ORIENTATIONS,
} from 'Client/utils/windowManipulations/scroll';
import { ProjectGaudi } from 'Shared/types';
import { getNearestProjects } from 'Client/services/project/getNearestProjects';
import { ProjectCard } from 'Atoms';
import { GET_PROGRAMMES } from 'Client/services/programme/getProgrammes.gql';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { ProgressBars } from './ProgressBars';
import {
  Wrapper,
  Container,
  NextProposalContainer,
  CardContainer,
  NextProjectContainer,
} from './UserContributions.styles';

interface Props {
  project: ProjectGaudi;
  totalComments?: number;
  nextProposals?: Page<ProposalPageContent>[];
}

export const UserContributions: React.FC<Props> = ({
  project,
  totalComments = 0,
  nextProposals,
}) => {
  const [getProgrammes] = useLazyQuery(GET_PROGRAMMES, {});
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { apiToken } = useUtils();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [nearestProject, setNearProject] = React.useState<ProjectCardData>();
  const [loadingTile, setLoadingTile] = React.useState(false);

  const totalSurveys = nextProposals?.length + totalComments;
  const hasAnsweredAllSurveys = totalComments === totalSurveys;
  const nextProposal = (nextProposals || [])[0];
  const projectName = project.name;

  const NextProposal: React.FC = () => (
    <NextProposalContainer>
      <CardContainer content={t('Get started')}>
        <p>{t('Next tile')}</p>
        <CustomProposalCard
          proposal={{
            ...nextProposal,
            pageContent: {
              ...nextProposal.content,
            },
          }}
        />
      </CardContainer>
      <button
        onClick={() => {
          scrollTo({
            elementId: 'thanks-page-other-proposals',
            orientation: SCROLL_TO_ORIENTATIONS.VERTICALLY,
            /* Nav bar height (fixed element) not rendered on mobile */
            mod: isMobile ? 0 : 56,
          });
        }}
      >
        {t('See other tiles')}
      </button>
    </NextProposalContainer>
  );

  React.useEffect(() => {
    const getNearestProject = async () => {
      setLoadingTile(false);
      if (!hasAnsweredAllSurveys) return null;

      const projectLng =
        project?.longitude || project?.location?.coordinates[0];
      const projectLat = project?.latitude || project?.location?.coordinates[1];
      if (!projectLng || !projectLat) {
        captureMessage(`Project ${project._id} has no location`);
        return;
      }
      try {
        const customerNearestMongoProject = await getNearestProjects({
          latitude: projectLat,
          longitude: projectLng,
          currentProjectId: project._id,
          activeOnly: true,
          limit: 1,
          includeInternal: false,
          customer: project.customer,
          projectRequestsOnGql: project.features.projectRequestsOnGql,
          apiToken,
        });
        const nearestMongoProject = await getNearestProjects({
          latitude: projectLat,
          longitude: projectLng,
          currentProjectId: project._id,
          activeOnly: true,
          limit: 1,
          includeInternal: false,
          projectRequestsOnGql: project.features.projectRequestsOnGql,
          apiToken,
        });

        const nearestProject =
          customerNearestMongoProject[0] || nearestMongoProject[0];
        if (!nearestProject) return;
        setLoadingTile(true);
        const { data } = await getProgrammes({
          variables: {
            getProgrammesInput: {
              rules: {
                _id: {
                  condition: 'is-in',
                  value: String(nearestProject._id),
                },
              },
              order: 'random',
              lang: i18n.language,
              customerName: project.customer,
            },
          },
        });
        const programmeProject = data?.getProgrammes || [];
        setNearProject(programmeProject[0]);
      } catch (err) {
        captureException(err);
      } finally {
        setLoadingTile(false);
      }
    };
    getNearestProject();
  }, [hasAnsweredAllSurveys, i18n.language, project]);

  const NextProject: React.FC = () => {
    if (loadingTile)
      return (
        <NextProjectContainer>
          <p>{t('Loading next project...')}</p>
        </NextProjectContainer>
      );
    if (!nearestProject && !loadingTile) return null;
    return (
      <NextProjectContainer>
        <CardContainer content={t('Get started')} isProjectCard>
          <ProjectCard project={nearestProject} key={project._id} />
        </CardContainer>
      </NextProjectContainer>
    );
  };

  return (
    <Wrapper>
      <Container>
        <h2
          style={{
            fontSize: '1.3125rem',
            fontWeight: 700,
            textAlign: 'center',
            margin: 0,
          }}
        >
          {hasAnsweredAllSurveys
            ? t('Awesome, you’ve answered to all tiles on {{project}}.', {
                project: projectName,
              })
            : t(
                'Nice, you’ve contributed to {{totalComments}} out of {{totalSurveys}} tiles on {{project}}',
                {
                  project: projectName,
                  totalComments,
                  totalSurveys,
                }
              )}
        </h2>

        <div
          style={{
            padding: '2rem 0 1.5rem 0',
          }}
        >
          <ProgressBars
            totalSurveys={totalSurveys}
            totalComments={totalComments}
          />
        </div>

        <p
          style={{
            fontSize: '1rem',
            textAlign: 'center',
            margin: 0,
            paddingBottom: '2rem',
          }}
        >
          {hasAnsweredAllSurveys
            ? t('Explore more projects and make sure your voice is heard')
            : t('Make sure your voice is heard by responding to all the tiles')}
        </p>
        {!hasAnsweredAllSurveys && nextProposal ? (
          <NextProposal />
        ) : (
          <NextProject />
        )}
      </Container>
    </Wrapper>
  );
};
