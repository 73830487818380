import { gql } from '@apollo/client';
import { captureException } from '@sentry/node';
import getClient from 'Shared/utils/apollo-client';

const GET_CUSTOMER_PROJECTS = gql`
  query GetProjectsFromCustomerName(
    $customerName: String
    $currentProject: String
    $activeOnly: Boolean
  ) {
    getProjectsFromCustomerName(
      customerName: $customerName
      currentProject: $currentProject
      activeOnly: $activeOnly
    ) {
      _id
    }
  }
`;

export const getCustomerProjectsGql = async ({
  apiToken,
  customer,
  currentProject,
  activeOnly,
}: {
  apiToken: string;
  customer: string;
  currentProject?: string;
  activeOnly?: boolean;
}) => {
  try {
    const client = getClient(apiToken);
    const { data } = await client.query({
      query: GET_CUSTOMER_PROJECTS,
      variables: {
        customerName: customer,
        currentProject,
        activeOnly,
      },
      context: {
        fetchOptions: {
          method: 'GET',
        },
      },
    });
    return data.getProjectsFromCustomerName;
  } catch (error) {
    console.error(error);
    captureException(error);
    return null;
  }
};
