import { captureException } from '@sentry/node';
import { getCustomerProjectsGql } from './getCustomerProjects.gql';

interface GetCustomerProjectsProps {
  customer: string;
  currentProject?: string;
  activeOnly?: boolean;
  projectRequestsOnGql: boolean;
  apiToken: string;
}

const getCustomerProjectsOld = async ({
  customer,
  currentProject,
  activeOnly = false,
}) => {
  const res = await fetch(
    `/api/project/customerProjects?customer=${customer}${
      currentProject && `&currentProject=${currentProject}`
    }${activeOnly && `&activeOnly=${activeOnly}`}`
  );
  const jsonRes = (await res.json()) || null;
  return jsonRes?.data?.projects;
};
/**
 *
 * @param customer customer name (string)
 * @param currentProject project to be ignored, if given (_id)
 * @param activeOnly if true, only active projects will be returned
 * @returns
 */
export const getCustomerProjects = async ({
  customer,
  currentProject,
  activeOnly = false,
  projectRequestsOnGql,
  apiToken,
}: GetCustomerProjectsProps) => {
  try {
    if (projectRequestsOnGql) {
      return await getCustomerProjectsGql({
        apiToken,
        customer,
        currentProject,
        activeOnly,
      });
    }
    /* This needs to be deprecated once confirmed the feature above works as intended */
    return await getCustomerProjectsOld({
      customer,
      currentProject,
      activeOnly,
    });
  } catch (error) {
    captureException(error);
    return null;
  }
};
