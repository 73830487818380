import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/node';
import { useLazyQuery } from '@apollo/client';
import { getCustomerProjects } from 'Client/services/project/getCustomerProjects';
import { useProject } from 'Client/utils/hooks';
import { ProgrammeReel } from 'Client/pages/hub/components/Programme';
import { GET_PROGRAMMES } from 'Client/services/programme/getProgrammes.gql';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { Wrapper, Header } from './CustomerProjects.styles';

export const CustomerProjects = () => {
  const [getProgrammes] = useLazyQuery(GET_PROGRAMMES, {});
  const project = useProject();
  const { apiToken } = useUtils();
  const { t, i18n } = useTranslation();

  const [otherCustomerProjectTiles, setOtherCustomerProjectTiles] =
    React.useState([]);
  const [loadingTiles, setLoadingTiles] = React.useState(false);

  const { customer } = project;

  React.useEffect(() => {
    const getProjects = async (projectId: string) => {
      try {
        setLoadingTiles(false);
        const _otherCustomerProjects = await getCustomerProjects({
          customer,
          currentProject: projectId,
          activeOnly: true,
          projectRequestsOnGql: project.features.projectRequestsOnGql,
          apiToken,
        });
        setLoadingTiles(true);
        const { data } = await getProgrammes({
          variables: {
            getProgrammesInput: {
              rules: {
                _id: {
                  condition: 'is-in',
                  value: _otherCustomerProjects
                    .map((project) => String(project._id))
                    .join(';'),
                },
              },
              order: 'random',
              lang: i18n.language,
              customerName: customer,
            },
          },
        });
        const programmeTiles = data?.getProgrammes || [];
        setOtherCustomerProjectTiles(programmeTiles);
      } catch (err) {
        captureException(err);
      } finally {
        setLoadingTiles(false);
      }
    };
    getProjects(project._id);
  }, [customer, i18n.language, project]);

  if (loadingTiles) return <p>{t('Loading customer projects...')}</p>;
  if (!otherCustomerProjectTiles?.length && !loadingTiles) return null;
  return (
    <Wrapper>
      <Header>
        <h2>{t('{{customer}} in action!', { customer })}</h2>
        <p>{t('Keep an eye out for their other engagements.')}</p>
      </Header>
      <div>
        <ProgrammeReel
          projects={otherCustomerProjectTiles}
          showCardsCounter={false}
        />
      </div>
    </Wrapper>
  );
};
